@import 'settings';
@import 'foundation';



// Foundation classes & modules
@include foundation-global-styles;
// @include foundation-flex-classes;
// @include foundation-visibility-classes;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-grid;
// @include foundation-button;
@include foundation-typography;
@include foundation-typography-helpers;

// @include foundation-prototype-spacing;
// @include foundation-prototype-position;
// @include foundation-forms;

body {
  margin: 0;
  padding: 0;
}

.section_one,
.section-two {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: rem-calc(20);
  box-sizing: border-box;
  // float: left;

  @include breakpoint(large up) {
    height: 100vh;
    width: 50%;
  }
}

.section_two {
  padding: rem-calc(60);
  // align-items: center;
  justify-items: center;
  align-items: center;
  justify-content: center;
  background-color: #faa232;
  min-height: 100vh;
  box-sizing: border-box;

  @include breakpoint(medium only) {
    width: 100%;
    min-height: auto;
    height: auto;
  }

  @include breakpoint(medium up) {
    padding: rem-calc(100);
  }

  img {
    max-width: 80%;
    margin-top: rem-calc(20);
    maring-bottom: rem-calc(20);


  }
}

.logo {
  width: rem-calc(200);
  padding-bottom: rem-calc(40);
}

.gray {
  color: gray;
}

.books-container {
  float: relative;
}

.books {
  @include breakpoint(large up) {
    position: absolute;
    top: 50%;
    margin-left: rem-calc(-50);
    transform: translateY(-50%);
    padding: rem-calc(20);
  }
}